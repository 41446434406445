import { DatePicker, InputNumber } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import Field from 'components/Form/Field';
import WithFormHooksController from 'components/common/WithFormHooksController';
import DateFormats from 'components/config/date';
import React from 'react';

import { useFormHooks } from 'context/FormHooksContext';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

export default function DetailsForm() {
  const { control } = useFormHooks();
  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return (
      current && current < dayjs().subtract(1, 'day').utc().local().endOf('day')
    );
  };

  dayjs.extend(customParseFormat);

  return (
    <div className="grid gap-3 grid-cols-12 h-auto px-3 mt-3">
      <Field label="*Rate" span={12}>
        <WithFormHooksController
          control={control}
          name="rate"
          component={
            <InputNumber<number> addonAfter="%" className="rounded-md w-full" />
          }
        />
      </Field>
      <Field label="*Start Date" span={12}>
        <WithFormHooksController
          control={control}
          name="startDate"
          component={
            <DatePicker
              disabledDate={disabledDate}
              className="px-2 rounded-md"
              showTime={{ defaultValue: dayjs('00:00', 'HH:mm') }}
              format={DateFormats.asDisplayDateTime}
            />
          }
        />
      </Field>
      <Field label="*End Date" span={12}>
        <WithFormHooksController
          control={control}
          name="endDate"
          component={
            <DatePicker
              disabledDate={disabledDate}
              showTime={{ defaultValue: dayjs('23:59', 'HH:mm') }}
              format={DateFormats.asDisplayDateTime}
            />
          }
        />
      </Field>
    </div>
  );
}
