import { format, utcToZonedTime } from 'date-fns-tz';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import DateFormats from 'components/config/date';

dayjs.extend(utc);
dayjs.extend(duration);

export const addYear = (years: number) =>
  new Date(new Date().setFullYear(new Date().getFullYear() + years));

export const serverTimeToLocalTime = (
  utcISODateTime: string,
  pattern: string,
) => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate = utcToZonedTime(utcISODateTime, tz);
  let formattedDate = ``;

  try {
    formattedDate = format(zonedDate, pattern, {
      timeZone: tz,
    });
    // eslint-disable-next-line no-empty
  } catch {
    // TODO: Log Error
  }

  return formattedDate;
};

export const dateCalculateRule = date => {
  const today = dayjs();

  const dateDiff = dayjs(date).diff(today);
  const durationInDays = dayjs.duration(dateDiff).days();
  const formattedDate = dayjs(date).utc().format(DateFormats.asDisplayDateTime);

  return `${formattedDate} (${durationInDays})`;
};

/**
 * Converts a UTC date to a local date and time string.
 * @param date The UTC date to convert.
 * @returns The local date and time as a string in the format 'YYYY-MM-DDTHH:mm:ss'.
 */
export const utcToLocalDate = (date: string, format: string): string => {
  const inputDate = dayjs.utc(date).format();
  const utcDate = dayjs.utc().format(DateFormats.asPayloadDateTime);
  const localDate = dayjs(new Date()).format(DateFormats.asPayloadDateTime);
  const hoursDiff = dayjs(localDate).diff(utcDate, 'hours');

  return dayjs(inputDate).add(hoursDiff, 'hours').format(format);
};

export function dateQueryFormatter(value) {
  const { dateRangePicker, dateTypeSelector } = value;

  return {
    [dateTypeSelector]: {
      condition1: {
        dateFrom: dayjs(dateRangePicker?.gte).format(DateFormats.asPayloadDate),
        dateTo: null,
        filterType: 'date',
        type: 'greaterThan',
      },
      condition2: {
        dateFrom: dayjs(dateRangePicker?.lte).format(DateFormats.asPayloadDate),
        dateTo: null,
        filterType: 'date',
        type: 'lessThan',
      },
      filterType: 'date',
      operator: 'AND',
    },
  };
}
