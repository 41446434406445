import { IconRef } from 'components/IconSheet';
import { dateTypeSelectorOptions } from 'components/Workspace/defaultConfigs/types/common';
import { ModuleTypes } from 'components/Workspace/defaultConfigs/types/modules';
import { dateQueryFormatter } from 'helpers/date';
import { bookingFilterEngines } from 'helpers/filterEngines';

export const invoicingScreenConfig = {
  id: 'invoicing',
  module: ModuleTypes.Invoice,
  gridIcon: IconRef.menuInvoice,
  initGridHeight: 170,
  pagination: true,
  pageSize: 50,
  toolbar: {
    filters: [
      {
        field: 'dateTypeSelector',
        size: 'small',

        defaultValue: 'bookedDate',
        style: {
          width: 140,
        },
        options: dateTypeSelectorOptions,
      },
      {
        field: 'dateRangePicker',
        size: 'small',

        style: {
          color: 'white',
          width: 240,
        },
      },
    ],
    jobs: [
      {
        id: 'verify',
        label: 'Verify',
        tooltip: 'Verify the selected bookings.',
      },
      {
        id: 'unverify',
        label: 'Unverify',
        tooltip: 'Unverify the selected bookings.',
      },
      {
        id: 'invoice',
        label: 'Invoice',
        tooltip: 'Invoice the selected bookings.',
      },
    ],
  },
  screenViews: {
    label: 'Your Views',
  },

  filterEngines: bookingFilterEngines,
  dateQueryFormatter,
};
