import {
  faArrowsToDot,
  faBackward,
  faBan,
  faBoxesPacking,
  faCalendarDays,
  faCheckCircle,
  faCheckToSlot,
  faList,
  faShuffle,
  faTruckRampBox,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Popconfirm, Tooltip, message } from 'antd';
import useBookings from 'context/bookings/hooks';
import useInvoicing from 'context/financials_invoicing/hooks';
import useAuthentication from 'context/security_authentication/hook';
import { dispatchActionValidator } from 'helpers/dispatchActionValidator';
import React from 'react';
import { styled } from 'twin.macro';
import { SecondaryAction } from '../../ToolbarActions/ActionButtons';

type Props = Readonly<{
  jobs?: any[];
  selectedItems?: any[];
  module?: string;
  onUprater?: () => void;
}>;

const StyledActionItem = styled.div`
  .ant-btn-sm {
    border: 0;
    color: ${p => p.theme.primaryButtonTextColor};
    background-color: ${p => p.theme.primaryButtonColor};
    font-size: 12px;
    display: flex;
    align-items: center;
  }
`;

function DispatchActions(props: Props) {
  // = ==================== init =========================//
  const { jobs, module, selectedItems, onUprater } = props;
  const {
    pendingAcceptBooking,
    pendingAllocateBooking,
    pendingUpliftBooking,
    pendingOffloadBooking,
    pendingUnallocateBooking,
    pendingReAllocateBooking,
    pendingCancelBooking,

    showSubModal,
    acceptBooking,
    upliftBooking,
    offloadBooking,
    unAllocateBooking,
    setUpdatedItem,
    cancelBooking,
  } = useBookings();

  const {
    verifyBooking,
    unverifyBooking,
    invoiceBooking,
    setUpdatedInvoice,
    pendingVerifyBooking,
    pendingInvoiceBooking,
  } = useInvoicing();

  const { isAllow } = useAuthentication();

  // ===================== handler =========================//

  const handleAllocate = () => {
    const actionValidator = dispatchActionValidator('allocate', selectedItems);
    if (actionValidator?.message) {
      message.error(actionValidator.message);
      return;
    }
    showSubModal({
      name: 'selectVehicle',
      visible: true,
    });
  };

  const handleReAllocate = () => {
    const actionValidator = dispatchActionValidator(
      'reallocate',
      selectedItems,
    );
    if (actionValidator?.message) {
      message.error(actionValidator.message);
      return;
    }
    showSubModal({
      name: 'reSelectVehicle',
      visible: true,
    });
  };

  const handleUpdateItems = (result, action) => {
    if (result?.length > 0) {
      setUpdatedItem(result);
      message.success(`${action} bookings successfully!`);
    } else {
      message.error(`Cannot ${action} bookings`);
    }
  };

  const handleSetDates = () => {
    showSubModal({
      name: 'setDates',
      visible: true,
    });
  };

  const handleAccept = () => {
    const actionValidator = dispatchActionValidator('accept', selectedItems);
    if (actionValidator?.message) {
      message.error(actionValidator.message);
      return;
    }
    const bookingIds = selectedItems?.map(item => item.id) as [number];
    acceptBooking(bookingIds).then(res => {
      const successItems = res?.data?.acceptBookings?.bookingViews;
      handleUpdateItems(successItems, 'Accept');
    });
  };

  const handleUplift = () => {
    const actionValidator = dispatchActionValidator('uplift', selectedItems);
    if (actionValidator?.message) {
      message.error(actionValidator.message);
      return;
    }
    const bookingIds = selectedItems?.map(item => item.id) as [number];
    upliftBooking(bookingIds).then(res => {
      const successItems = res?.data?.upliftBookings?.bookingViews;
      handleUpdateItems(successItems, 'Uplift');
    });
  };

  const handleOffload = () => {
    const actionValidator = dispatchActionValidator('offload', selectedItems);
    if (actionValidator?.message) {
      message.error(actionValidator.message);
      return;
    }
    const bookingIds = selectedItems?.map(item => item.id) as [number];
    offloadBooking(bookingIds).then(res => {
      const successItems = res?.data?.offloadBookings?.bookingViews;
      handleUpdateItems(successItems, 'Offload');
    });
  };

  const handleUnAllocate = () => {
    const actionValidator = dispatchActionValidator(
      'unallocate',
      selectedItems,
    );
    if (actionValidator?.message) {
      message.error(actionValidator.message);
      return;
    }
    const bookingIds = selectedItems?.map(item => item.id) as [number];
    unAllocateBooking(bookingIds).then(res => {
      const successItems = res?.data?.unallocateBookings?.bookingViews;
      handleUpdateItems(successItems, 'Unallocate');
    });
  };
  const handleCancel = () => {
    const actionValidator = dispatchActionValidator('cancel', selectedItems);
    if (actionValidator?.message) {
      message.error(actionValidator.message);
      return;
    }
    const bookingIds = selectedItems?.map(item => item.id) as [number];
    cancelBooking(bookingIds).then(res => {
      const successItems = res?.data?.cancelBookings?.bookingViews;
      handleUpdateItems(successItems, 'Cancel');
    });
  };

  const handleVerifyBooking = () => {
    verifyBooking({
      bookingIds: selectedItems.map(item => item.id),
    }).then(res => {
      const successItems =
        res?.data?.verifyBookings?.invoiceOutput?.validBookingViews;
      if (successItems?.length > 0) {
        setUpdatedInvoice(successItems);
        message.success('Verifying booking(s) successfully!');
      } else {
        message.error('Cannot verify booking(s)');
      }
    });
  };

  const handleUnverifyBooking = () => {
    unverifyBooking({
      bookingIds: selectedItems.map(item => item.id),
    }).then(res => {
      const successItems =
        res?.data?.unverifyBookings?.invoiceOutput?.validBookingViews;
      if (successItems?.length > 0) {
        setUpdatedInvoice(successItems);
        message.success('Unverifying booking(s) successfully!');
      } else {
        message.error('Cannot unverify booking(s)');
      }
    });
  };

  const handleInvoiceBooking = () => {
    invoiceBooking({
      bookingIds: selectedItems.map(item => item.id),
    }).then(res => {
      const successItems =
        res?.data?.invoiceBookings?.invoiceOutput?.validbookingViews;
      if (successItems?.length > 0) {
        setUpdatedInvoice(successItems);
        message.success('Invoicing bookings successfully!');
      } else {
        message.error('Cannot invoice bookings');
      }
    });
  };

  return (
    <div className="flex whitespace-nowrap">
      {jobs?.map(item => {
        const disabled = selectedItems?.length <= 0;
        if (isAllow(item?.id, module, false)) {
          return (
            <StyledActionItem key={item.id}>
              <Tooltip placement="topLeft" title={item.tooltip} color="black">
                {
                  {
                    setdates: (
                      <SecondaryAction
                        onClick={handleSetDates}
                        disabled={disabled}
                        icon={faCalendarDays}
                        label={item.label}
                        tooltip="Set Dates"
                      />
                    ),
                    allocate: (
                      <SecondaryAction
                        onClick={handleAllocate}
                        disabled={
                          disabled ||
                          !!dispatchActionValidator('allocate', selectedItems)
                            ?.message
                        }
                        loading={pendingAllocateBooking}
                        icon={faArrowsToDot}
                        label={item.label}
                        tooltip="Allocate booking"
                      />
                    ),
                    accept: (
                      <SecondaryAction
                        tooltip="Accept booking"
                        onClick={handleAccept}
                        disabled={
                          disabled ||
                          !!dispatchActionValidator('accept', selectedItems)
                            ?.message
                        }
                        icon={faCheckCircle}
                        label={item.label}
                        loading={pendingAcceptBooking}
                      />
                    ),

                    uplift: (
                      <SecondaryAction
                        tooltip="Uplift"
                        onClick={handleUplift}
                        disabled={
                          disabled ||
                          !!dispatchActionValidator('uplift', selectedItems)
                            ?.message
                        }
                        icon={faBoxesPacking}
                        label={item.label}
                        loading={pendingUpliftBooking}
                      />
                    ),
                    offload: (
                      <SecondaryAction
                        tooltip="Offload"
                        onClick={handleOffload}
                        disabled={
                          disabled ||
                          !!dispatchActionValidator('offload', selectedItems)
                            ?.message
                        }
                        icon={faTruckRampBox}
                        label={item.label}
                        loading={pendingOffloadBooking}
                      />
                    ),
                    unallocate: (
                      <SecondaryAction
                        tooltip="Unallocate"
                        onClick={handleUnAllocate}
                        disabled={
                          disabled ||
                          pendingUnallocateBooking ||
                          !!dispatchActionValidator('unallocate', selectedItems)
                            ?.message
                        }
                        icon={faList}
                        label={item.label}
                      />
                    ),
                    reset: (
                      <SecondaryAction
                        tooltip="Reallocate"
                        onClick={handleReAllocate}
                        disabled={
                          disabled ||
                          pendingReAllocateBooking ||
                          !!dispatchActionValidator('reallocate', selectedItems)
                            ?.message
                        }
                        icon={faShuffle}
                        label={item.label}
                      />
                    ),
                    cancel: (
                      <Popconfirm
                        className="flex bg-transparent items-center"
                        placement="leftBottom"
                        title="Confirm cancel booking?"
                        onConfirm={handleCancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <SecondaryAction
                          tooltip="Cancel"
                          disabled={disabled || pendingCancelBooking}
                          icon={faBan}
                          label={item.label}
                        />
                      </Popconfirm>
                    ),
                    percentageUprater: (
                      <Button
                        onClick={onUprater}
                        className="w-full"
                        style={{
                          width: '100%',
                          color: 'white',
                          opacity: disabled ? 0.7 : 1,
                        }}
                        size="small"
                        disabled={disabled}
                      >
                        {item.label}
                      </Button>
                    ),
                    // actions for invoicing
                    verify: (
                      <SecondaryAction
                        tooltip="Verify"
                        onClick={handleVerifyBooking}
                        disabled={disabled || pendingVerifyBooking}
                        icon={faCheckToSlot}
                        label={item.label}
                      />
                    ),
                    unverify: (
                      <SecondaryAction
                        tooltip="Invoice"
                        onClick={handleUnverifyBooking}
                        disabled={disabled || pendingInvoiceBooking}
                        icon={faBackward}
                        label={item.label}
                      />
                    ),
                    invoice: (
                      <SecondaryAction
                        tooltip="Invoice"
                        onClick={handleInvoiceBooking}
                        disabled={disabled || pendingInvoiceBooking}
                        icon={faShuffle}
                        label={item.label}
                      />
                    ),

                    rerate: (
                      <Button
                        className="w-full"
                        style={{
                          width: '100%',
                          color: 'white',
                          opacity: disabled ? 0.7 : 1,
                        }}
                        size="small"
                        disabled={disabled}
                      >
                        {item.label}
                      </Button>
                    ),
                  }[item.id]
                }
              </Tooltip>
            </StyledActionItem>
          );
        }
        return null;
      })}
    </div>
  );
}

export default DispatchActions;
