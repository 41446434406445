import { BookingAttributeModel } from 'api/graphql/generated/serviceTypesAndHooks';
import dayjs from 'dayjs';

export const mappedContainerInfo = (
  rawData, // : GetContainerVisitResponse,
  customAttributes: [BookingAttributeModel],
) => {
  const portCodeAttribute = customAttributes?.find(
    item => item.name === 'ContainerPortCode',
  );
  const portCodeValue = portCodeAttribute?.values?.find(
    item => item.name === rawData?.portCode,
  );
  return {
    ContainerNumber: rawData.containerNumber,
    ContainerJobType: rawData.categoryId,
    ContainerOwner: rawData.containerOperatorName,
    ContainerVesselName: rawData.outboundVesselName,
    ContainerVoyageNo: rawData.containerOperatorVoyageId,
    ContainerSize: rawData.containerSizeId,
    ContainerType: rawData.containerIsoTypeId,
    ContainerFullEmpty: rawData.containerStatusId,
    ContainerSealNo: '',
    ContainerIsoTypeCode: rawData?.containerIsoTypeCode || '',
    ContainerDemurrageDate: rawData?.lastFreeDatetime
      ? dayjs(rawData?.lastFreeDatetime)
      : null,
    ContainerDischargedDate: rawData?.dischargedDatetime
      ? dayjs(rawData?.dischargedDatetime)
      : null,
    ContainerDetentionDate: rawData?.detentionDatetime
      ? dayjs(rawData?.detentionDatetime)
      : null,
    ContainerSlotDate: rawData?.vbsSlotDatetime
      ? dayjs(rawData?.vbsSlotDatetime)
      : null,
    ContainerSlotReference: '',
    ContainerPortCode: portCodeValue.id,
  };
};

const letterToNumber = (letter: string) => {
  const charIndex = letter.toUpperCase().charCodeAt(0) - 55;
  if (charIndex < 10 || charIndex > 35) {
    return {
      number: 0,
      success: false,
    }; // Indicate failure
  }
  return {
    number: charIndex + Math.floor((charIndex - 1) / 10),
    success: true,
  }; // Indicate success
};

export const validateContainerNumber = (containerNumber: string) => {
  let valid = false;
  const errors = [];
  if (containerNumber.length !== 11) return { valid, errors };
  let checksum = 0;

  for (let i = 0; i < 10; i++) {
    let result = { number: 0, success: false };
    if (i < 4) {
      result = letterToNumber(containerNumber[i]);
    } else {
      result.number = parseInt(containerNumber[i], 10);
      result.success = true;
    }
    if (!result?.success) {
      errors.push(
        `Container Number: Character ${containerNumber[i]} is invalid`,
      );
    }
    checksum += Math.pow(2, i) * result.number;
  }
  const checkDigit = (checksum % 11) % 10;
  valid = checkDigit === parseInt(containerNumber[10], 10);
  return {
    valid,
    errors,
  };
};
