import { Select, SelectProps } from 'antd';
import { useGetDivisionsLazyQuery } from 'api/graphql/generated/serviceTypesAndHooks';
import WithFormHooksController from 'components/common/WithFormHooksController';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';

type Props = Readonly<{
  control: any;
  name?: string;
  componentProps?: Partial<SelectProps>;
  onChange?: (value: any) => void;
}>;

export default function FormControlledDivisionSelect(props: Props) {
  const { control, name, componentProps, onChange } = props;
  const [options, setOptions] = useState(null);
  const [selectedValue, setSelectedValue] = useState(
    control._defaultValues?.divisionIds,
  );

  const [getDivisions, { loading }] = useGetDivisionsLazyQuery({
    onCompleted: (response: any) => {
      const {
        allDivisions: { nodes },
      } = response;

      if (nodes?.length) {
        const options = nodes.map((data: any) => {
          return {
            label: `${data?.name}`,
            value: data.id,
          };
        });
        const defaultOption = control._defaultValues?.divisionIds;
        if (defaultOption) {
          addOptionFunction(options, defaultOption);
        }
        setOptions(options);
      }
    },
  });

  const addOptionFunction = (options: any, defaultOption: any) => {
    const existedOption = options.find(
      (option: any) => option.value === defaultOption.id,
    );
    if (!existedOption) {
      options.push({
        label: `${defaultOption?.name}`,
        value: defaultOption.id,
      });
    }
  };

  const _debouncedSearch = (value: string = '') => {
    getDivisions({
      variables: {
        filters: { name: { contains: value } },
      },
    });
    return true;
  };

  useEffect(() => {
    getDivisions({
      variables: {
        filters: null,
        cursor: null,
      },
    });
  }, []);

  function _onChange(value: any) {
    setSelectedValue(value);
    onChange?.(value);
  }

  return (
    <WithFormHooksController
      control={control}
      name={name}
      component={
        <Select
          {...componentProps}
          loading={loading}
          value={selectedValue}
          placeholder="Select a division"
          onChange={_onChange}
          popupClassName="certain-category-search-dropdown"
          options={options}
          filterOption={(input, option) =>
            option?.label
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          onSearch={debounce(_debouncedSearch, 400)}
        />
      }
    />
  );
}
